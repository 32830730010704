<template>
    <div class="consent-field flex flex-col gap-2" :class="`consent-field--${type}`">
        <div class="w-full flex flex-wrap justify-between md:flex-row items-baseline pt-2 .consentt">
            <div
                v-if="previewMode || personalDetails || userData"
                class="consent-field__personal-details mr-4 flex print--none"
            >
                Do you agree with this consent? 
                <span class="inline text-red-500" v-if="fieldData.mandatory">*</span>
                  
            </div>

            <div class="flex items-center">
                <div class="username-section">
                    <span class="mt-1 font-medium capitalize username print--none">
                        <template v-if="previewMode">First Name Last Name</template>
                        <template v-else-if="personalDetails" >{{ personalDetails.first_name }} {{ personalDetails.last_name }}</template>
                        <template v-else-if="userData">{{ userData?.name }}</template>
                    </span>
                </div>
                <div class="consent-field__buttons flex items-center pt-1">
                    <div class="form-check flex mr-4 print--none" v-for="(option, idx) in typeConfig[type].options" :key="option.option_value + idx">
                       <div>
                        <label class="flex" :class="{ responsiveLabel: previewForm }">
                            <input
                                class="form-check-input appearance-none h-4 w-4 border border-solid rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                :class="{
                                    'border-box-stroke': !error,
                                    'border-red-600': error,
                                }"
                                :name="getName"
                                v-model="getValue"
                                :value="option.option_value"
                                :type="type"
                                :disabled="disabled || formAlreadySubmitted"
                                :id="option.option_value + 'consent' + idx" @change="changeValue"
                            />
                            <!-- :value="option" v-model="getValue"  -->
                            <span class="form-check-label inline-block text-gray-800 cursor-pointer capitalize no-print" :for="option.option_value + 'consent' + idx">
                                {{ option.option_label }} 
                            </span>
                        </label>
                       </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="signDate" class="flex self-end no-print">
            {{ signDate }}
        </div>
        <modalContent
            :name="fieldData.field_fe_id? fieldData.field_fe_id: fieldData.field_id" 
            ref="modal"
            title="Consent"
            close
            width="90%"
            height="88%"
            class="modalContent"
            :showFooter="false"
            :isConsentModal="true"
        >
            <template #content>
            <div clss="modal-content w-full">
                <div
                    class="w-full consent-content"
                    v-html="fieldData.placeholder"
                ></div>
            </div>
            </template>
        </modalContent>
        <div v-if="!hasSignature" class="flex">
            <div class="w-10/12 show-print">
                <div><b>Applicant Name:</b></div>
                {{ personalDetails?.first_name }} {{ personalDetails?.last_name }}
            </div>
            <div class="w-2/12 show-print">
                <div><b>Date:</b></div>
                {{ convertedSignDate }}
            </div>
        </div>
    </div>
</template>

<script>
import sections from "../../store";
import { isJson } from '../../utils';
import { DateTime } from "luxon";
const ModalContent = () => import("@shared/modal-content");

export default {
    components: {
        ModalContent,
    },
    props: {
        fieldData: {
            type: Object,
            require: true,
        },
        userData: {
            type: Object,
            default:()=> {}
        },
        error: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: "radio",
        },
        // is used to preview form before creating it in an admin panel:
        previewMode: {
            type: Boolean,
            default: false
        },
        // is used to prview filled form before its submitting:
        previewForm:{
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        hasSignature:{
            type: Boolean
        },
        parentData:{
            type: Object,
        },
        formAlreadySubmitted: {
            type: Boolean,
        }
    },
    data() {
        return {
            typeConfig: {
                radio: {
                    options: [
                        {
                            option_label: "Yes",
                            option_value: "agree",
                        },
                        {
                            option_label: "No",
                            option_value: "disagree",
                        },
                    ],
                },
                checkbox: {
                    options: [
                        {
                            option_label: "Yes",
                            option_value: "agree",
                        },
                    ],
                },
            },
        };
    },
    computed: {
        personalDetails() {
            return sections?.personal_details;
        },
        getOptions() {
            return this.fieldData.options || [];
        },
        getName() {
            if(this.fieldData.field_fe_id)
                return this.fieldData.field_fe_id + "_consent" + `_preview:${this.previewForm}`;
            else return this.fieldData.field_id + "_consent" + `_preview:${this.previewForm}`;
        },
        fieldValue() {
            let result;
            if (isJson(this.fieldData.value)) {
                result = JSON.parse(this.fieldData.value);
            } else result = {};
            return result;
        },
        getValue: {
            get() {
                let result;
                switch (this.type) {
                    case "checkbox":
                        result = this.fieldValue.value ? [this.fieldValue.value] : [];
                        break;
                    case "radio":
                        result = this.fieldValue.value || null;
                        break;
                    default:
                        break;
                }
                return result;
            },
            set(newValue) {
                if (!newValue){
                    this.$emit("input", null);
                    return;
                }
                let resultValue;
                switch (this.type) {
                    case "checkbox":
                        resultValue = {
                            value: newValue[0] || "disagree",
                            date: Date.now(),
                        };
                        break;
                    case "radio":
                        resultValue = {
                            value: newValue || "disagree",
                            date: Date.now(),
                        };
                        break;
                    default:
                        break;
                }
                this.$emit("input", JSON.stringify(resultValue));
            },
        },
        signDate() {
        // return this.fieldValue.date ?
        //     new Date(this.fieldValue.date).toLocaleString("en-GB") :
        //     '';
            const options = { day: 'numeric', month: 'numeric', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true, hourCycle: 'h12',};
            return this.fieldValue.date ?
            new Date(this.fieldValue.date).toLocaleString("en-GB",options) :
            '';
        },
        convertedSignDate() {
            return this.fieldValue.date ?
                DateTime.fromJSDate(new Date(this.fieldValue.date)).toFormat('dd LLL yyyy')  :
                '';
        },
    },
    methods: {
        // handle checkbox deselect:
        changeValue(el) {
            this.$emit("handleFieldValueChnage", el);
            if (!this.type === "checkbox") return;
            const selected = el.target.checked;
            if (!selected) this.$emit("input", "disagree");
        },
        showExpandModal() {
            this.$refs.modal.showModal(this.fieldData.field_fe_id);
        },
    },
};
</script>

<style lang="scss" scoped>
.consent-field {
    &--radio {
        .form-check-input {
            @apply rounded-full;
        }
        .checked\:bg-blue-600:checked {
            --bg-opacity: 1;
            background-color: rgba(37, 99, 235, var(--bg-opacity));
        }
        .form-check-input:checked[type="radio"] {
            background-image: url("data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%22-4 -4 8 8%22%3E%3Ccircle r=%222%22 fill=%22%23fff%22/%3E%3C/svg%3E");
        }
        .checked\:border-blue-600:checked {
            --border-opacity: 1;
            border-color: rgba(37, 99, 235, var(--border-opacity));
        }
        .form-check-input:disabled {
            pointer-events: none;
            filter: none;
            opacity: 0.5;
        }
        .form-check-input:checked {
            // background-color: #0d6efd;
            // border-color: #0d6efd;
            @apply border-primary bg-primary;
        }
    }
    &--checkbox {
        .checked\:border-blue-600:checked {
            --border-opacity: 1;
            border-color: rgba(37, 99, 235, var(--border-opacity));
        }
        .form-check-input:checked {
            // background-color: #0d6efd;
            // border-color: #0d6efd;
            @apply border-primary bg-primary;
        }
        .form-check-input:checked[type="checkbox"] {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
        }
        .checked\:bg-blue-600:checked {
            --bg-opacity: 1;
            background-color: rgba(37, 99, 235, var(--bg-opacity));
        }
    }
 
}

.username{
    margin-right: 20px;
}

@media screen and (max-width:600px) and (max-height:1400px){
    .consent-field__personal-details{ 
      width:100%;
   }

    .consent-content{
        width:100% !important;
        text-align:justify;
        overflow-wrap:break-word;
        word-break:break-word;
        hyphens:auto;
        margin:auto;
        height:100% !important;
        overflow-y:scroll;
    }
.username-section {
        width:100% !important;
       display: block;
    }
   

 .consentt{
    display: grid;
    grid-template-rows:1fr !important;
    grid-row-gap:0 !important;
    padding: 0;
    margin: 0 !important;
 }
 
.username-section{
    width:50% !important;
    text-align: justify !important;
    float: left;
    display: flex;
    padding:0 !important;

 }
  
 .consent-field__buttons{
    width: 100%;
 }
 .consent-field__personal-details{
    width:fit-content !important;
}

.form-check{
    width:26% !important;
}
}


@media screen and (min-width:700px) and (max-width:900px) and (max-height:1400px){

    .consent-question{
    display: flex;
}

.consent-field__personal-details{
    width:50% !important;
}
.username-section{
    width:fit-content !important;
    padding: 0;
    text-align: center;
}
.consentt{
    display: grid;
    grid-template-columns: 8fr 1fr 1fr;
}

.consent-field__buttons{
        width: fit-content;
    }
}

@media screen and (min-width:901px) and (max-width: 1250px) and (max-height:1400px){
    
.consent-question{
    display: flex;
}

.consent-field__personal-details{
    width: 55% !important;
}
.username-section{
    width:fit-content;
    padding: 0;
    text-align: center;
}
.consentt{
    display: grid;
    grid-template-columns: 8fr 1fr 1fr;
}

.consent-field__buttons{
        width: fit-content;
    }
}

@media screen and (min-width:1251px) and (max-width: 1400px) and (max-height:1400px){
    
    .consent-question{
        display: flex;
    }
    
    .consent-field__personal-details{
        width: 62% !important;
    }
    .username-section{
        width:fit-content;
        padding: 0;
        text-align: center;
    }
    .consentt{
        display: grid;
        grid-template-columns: 8fr 1fr 1fr;
    }

    .consent-field__buttons{
        width: fit-content;
    }
    }

@media screen and (min-width: 1401px) and (max-width:1520px) and (max-height:1400px){
    
.consent-question{
    display: flex;
}

.consent-field__personal-details{
    width:60%;
}
.username-section{
    width: fit-content;
    padding: 0;
    text-align: center;
}
.consentt{
    display: grid;
    grid-template-columns: 8fr 1fr 1fr;
}
}


@media screen and (min-width: 1521px) and (max-width:1750px) and (max-height:1400px){
    
    .consent-question{
        display: flex;
    }
    
    .consent-field__personal-details{
        width:67%;
    }
    .username-section{
        width: fit-content;
        padding: 0;
        text-align: center;
    }
    .consentt{
        display: grid;
        grid-template-columns: 8fr 1fr 1fr;
    }
    }

    @media screen and (min-width: 1751px) and (max-width:1900px) and (max-height:1400px){
    
    .consent-question{
        display: flex;
    }
    
    .consent-field__personal-details{
        width:72% !important;
    }
    .username-section{
        width: fit-content;
        padding: 0;
        text-align: center;
    }
    .consentt{
        display: grid;
        grid-template-columns: 8fr 1fr 1fr;
    }
    }


    @media screen and (min-width: 1901px) and (max-width:2200px) and (max-height:1400px) {
    
    .consent-question{
        display: flex;
    }
    
    .consent-field__personal-details{
        width:80% !important;
    }
    .username-section{
        width: fit-content;
        padding: 0;
        text-align: center;
    }
    .consentt{
        display: grid;
        grid-template-columns: 8fr 1fr 1fr;
    }
    }

@media screen and (min-width: 300px) and (max-width: 768px){
    .responsiveLabel{
        display:flex;
    }

    .form-check{
    width:35% !important;
}
}
</style>
